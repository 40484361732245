<template>
  <div>

    <Head></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li>
            <div class="overlay-gradient"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center slider-text">
                  <div class="slider-text-inner">
                    <h1 class="heading-section">软件著作</h1>
                    <p>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>

    <div id="fh5co-course">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>软著证书</h2>
          </div>
        </div>
<!--        移动端-->
        <el-carousel v-if="isMobile" :interval="4000" type="card">
          <el-carousel-item v-for="item in schemeList" :key="item">
            <el-image
                style="width: 100%;"
                :src="item"
                :preview-src-list="schemeList">
            </el-image>
          </el-carousel-item>
        </el-carousel>
<!--        pc端-->
        <el-carousel v-else :interval="4000" type="card" height="80vh">
          <el-carousel-item v-for="item in schemeList" :key="item">
            <el-image
                style="width: 100%;"
                :src="item"
                :preview-src-list="schemeList">
            </el-image>
          </el-carousel-item>
        </el-carousel>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import {getWorksListApi} from "../api/index"
export default {
  components: {
    Head,
    Footer,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    }
  },
  data() {
    return {
      schemeList: [],
    };
  },
  created() {
    // this.getWorks();
  },
  mounted() {
  },
  methods: {
    getWorks(){
      let params = {
        cates : 25,
      };
      getWorksListApi(params).then((res)=>{
        if(res.code == 200){
          let data = res.data;
          let arr = [];
          data.forEach(function(item){
            let img = item.image;
            // let val = img
            arr.push(img);
          })
          this.schemeList = arr;
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  //background-color: #d3dce6;
}
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}
.img_Soft{
    width: 40%;
    height: 400px;
    img {
        width: 100%;
        height: 100%;
    }
}

.row{
    display: flex;
}

::v-deep .my-gallery{   //deep深层作用选择器
    display: flex;
    height: 390px;
    figure{
        width: 30%;
        margin: 5px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (min-width: 390px) and (max-width: 969px) {
        ::v-deep .my-gallery{   //deep深层作用选择器
            display: flex;
            flex-direction: column;
            height: 100%;
            figure{
                width: 100%;
                margin: 5px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
}

::v-deep .pswp__button--share{
    display: none !important;
}

</style>
