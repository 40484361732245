import axios from 'axios'
/**
 * @description axios初始化
 */
const service = axios.create({
  baseURL:'https://www.dxtk-tech.com',
  // baseURL:'http://124.221.0.19',
  // baseURL:'/base',
  timeout: 500000,
  headers: {
    'Content-Type':'application/json;charset=UTF-8',
  }
})
// request拦截器
service.interceptors.request.use(
  config => {
  //  console.log('request',config);
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
// 可以在接口响应后统一处理结果
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || res.status || 201
    // 获取错误信息
    const msg = res.data.msg || '接口请求异常'
    if (code == 200) {
      return res.data
    } else if (code == 201) {
      return res
    } else {
      return Promise.reject(new Error(msg))
    }
  },
  error => {
    console.log('error', error)
    let { message } = error
    if (message == 'Network Error') {
      message = 'Network Error'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    return Promise.reject(error)
  }
)

export default service
