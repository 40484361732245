<template>
  <div>

    <Head :navId="1"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li :style="'background-image: url('+(bannerSwitch?banner:banner1)+');'"></li>
        </ul>
      </div>
    </aside>

    <div class="about-nav">
      <div v-for="(item,i) in nav" :key="i" @click="getContent(item.about_id,i)" :class="navIndex==i?'bavStyle':''" class="about-nav-div">{{item.title}}</div>
      <!-- <div @click="navIndex=1" :class="navIndex==1?'bavStyle':''" class="about-nav-div">
        企业文化
      </div>
      <div @click="navIndex=2" :class="navIndex==2?'bavStyle':''" class="about-nav-div">
        发展历程
      </div>-->
    </div>
    <!-- v-if="navIndex==nav[0].about_id" -->
    <div id="fh5co-about">
      <div class="about-flex" style="">
        <!-- <div class="col-md-8 animate-box"> -->
        <div class="animate-box about-center" style="margin-right:20px;" :style="detail.file_path?'width:40%':'width:70%'">
          <div v-html="detail.content"></div>
        </div>
        <div class="videp-div about-flex" style="width: 30%;" v-if="detail.file_path">
          <!-- <video :src="detail.file_path" :poster="videoImg" controls="controls" class="video-box"></video> -->
          <video controls="controls" height="auto" width="100%">
            <source :src="detail.file_path" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import { AboutApi, AboutContent } from "@/api/index";
import { getBanner } from "@/api/home";

export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      videoImg: require("../static/index/video_img.png"),
      imgSrc: require("../static/images/img_bg_gywm.jpg"),
      imgSrc_1: require("../static/images/fengmian.jpeg"),
      imgSrc_2: require("../static/images/img_bg_gywm.jpg"),
      imgSrc_3: require("../static/images/img_bg_5.jpg"),
      bannerdata: {},
      markList: [],
      navIndex: 0,
      nav: [],
      detail: {},
      banner: "",
      banner1: ""
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  mounted() {
    this.$store.commit('setSeo')
    this.navIndex = this.$route.query.id || 0;
    this.getAbout();
    getBanner(1).then((res) => {
      this.banner = res.data[0].img_path;
      this.banner1 = res.data[0].mobile_img_path;
    });
  },
  methods: {
    getAbout() {
      AboutApi().then((res) => {
        // console.log(res)
        if (res.status == 200) {
          this.nav = res.data;
          this.getContent(this.nav[this.navIndex].about_id, this.navIndex);
          // this.bannerdata = res.data;
        }
      });
    },
    getContent(i, a) {
      AboutContent({ about_id: i }).then((res) => {
        // console.log(res)
        if (res.status == 200) {
          // console.log(res);
          this.navIndex = a;
          this.detail = res.data;
          // this.bannerdata = res.data;
        }
      });
    },
    //友情链接
    getLink() {
      linkApi().then((res) => {
        if (res.code == 200) {
          const logoList = [];
          res.data.forEach((item, index) => {
            const mark = {
              logo: "",
              url: "",
            };
            let logo = item.logo;
            if (logo.includes("https://")) {
              mark.logo = logo;
            } else {
              mark.logo = this.$BaseUrl + logo;
            }

            mark.url = item.url;
            logoList.push(mark);
          });
          this.markList = logoList;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-carousel__container {
  position: relative;
}
.baaner_mb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-carousel__item h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
}
.el-carousel__item h2 {
  color: #fff;
  padding: 40px 80px;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

.img-class {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    width: 300px;
    height: 80px;
    margin: 10px;
  }
}
.authentication {
  display: flex;
  justify-content: center;
}
.about-nav {
  display: flex;
  width: 599px;
  border: 1px solid #becced;
  margin: 60px auto 0;
  &-div {
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #000000;
    cursor: pointer;
  }
  .bavStyle {
    color: #ffffff;
    background-image: linear-gradient(180deg, #4e85ff 0%, #1d5eed 100%);
  }
}
@media screen and (max-width: 768px) {
  .about-nav {
    width: 360px;
  }
  .about-nav-div {
    width: 120px;
  }
  .container {
    flex-direction: column;
  }
  .videp-div {
    width: 100% !important;
  }
  .about-center {
    margin-right: 0 !important;
    width: 95% !important;
  }
}
.about-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .about-flex {
    display: flex;
    flex-direction: column;
  }
  .videp-div {
    width: 100% !important;
  }
}
</style>
