import request from '@/utils/request'


//获取设置
export function getBanner(id) {
  return request({
    url: '/api/web/banner/index/' + id,
    method: 'get',
  })
}

//获取设置
export function pageImage() {
  return request({
    url: '/api/web/pageImage/index',
    method: 'get',
  })
}

//获取客服
export function kefuImage() {
  return request({
    url: '/api/web/kefuImage/index',
    method: 'get',
  })
}

//底部导航栏
export function footNav() {
  return request({
    url: '/api/web/search/getMenu',
    method: 'get',
  })
}

//关键字等信息
export function getSeo() {
  return request({
    url: '/api/web/settings/getSeo',
    method: 'get',
  })
}