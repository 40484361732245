<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { settings, linksList } from "@/api/index";
import { kefuImage } from "@/api/home";
export default {
  name: "app",
  mounted() {
    linksList().then((res) => {
      this.$store.state.link = res.data;
    });

    kefuImage().then((res) => {
      this.$store.state.qrCode = res.data;
    });

    settings().then((res) => {
      this.$store.state.setting = res.data;
      let setString = JSON.stringify(res.data);
      window.localStorage.setItem("settings", setString);
      
      this.$store.commit('shift')


    });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

#app {
  /* height: 100vh; */
  overflow-y: hidden;
}

.page-banner {
  background-color: #d3dce6;
  /* background: url('./static/images/img_bg_gywm.jpg') no-repeat bottom center; */
  background-size: cover;
  width: 100%;
  height: 100%;
}
.page-banner img {
  width: 100%;
  height: 100%;
}
.container-item {
  border-radius: 5px;
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
}
.container-item:hover {
  /* box-shadow: inset -70px 0px 77px 11px rgba(0, 0, 0, 0.2); */

  box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1) !important;
}
.container-item img {
  transition: all 0.2s ease-in-out;
}

.container-item:hover img {
  transform: scale(1.1);
  /* box-shadow: inset -70px 0px 77px 11px rgba(0, 0, 0, 0.2); */
}
/* ::v-deep  */
</style>