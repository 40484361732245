<template>
  <div>

    <Head :navId="3"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li :style="'background-image: url('+(bannerSwitch?banner:banner1)+');'"></li>
        </ul>
      </div>
    </aside>

    <div class="product-nav">
      <div v-for="(item,i) in nav" @click="getProduct(item.id,true)" :key="i" :class="navIndex==item.id?'bavStyle':''" class="product-nav-div">{{item.title}}</div>
      <!-- <div @click="navIndex=1" :class="navIndex==1?'bavStyle':''" class="product-nav-div">训练治疗类产品</div> -->
    </div>
    <!-- v-if="navIndex==0" -->
    <div id="fh5co-course">
      <div class="container">
        <div @click="toDetail(item)" class="container-item" v-for="(item,i) in content" :key="i">
          <div style="overflow: hidden;">
            <img :src="item.image_input" />
          </div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :page-size="20" :total="total" />
    </div>

    <Footer></Footer>
  </div>
</template>
  <script>
import { Head, Footer } from "../components/unit/index";
import { getWorksListApi, getWorksContent } from "../api/index";
import { getBanner } from "@/api/home";
export default {
  components: {
    Head,
    Footer,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data() {
    return {
      schemeList: [],
      backImg: require("../static/case/caseImg.png"),

      content: [],
      nav: [],
      navIndex: 0,
      total: 1,
      currentPage: 1,
      banner: "",
      banner1: ""
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  created() {
    this.getWorks();
  },
  mounted() {
    this.$store.commit('setSeo')
    this.navIndex = this.$route.query.id || 0;
    getBanner(3).then((res) => {
      this.banner = res.data[0].img_path;
      this.banner1 = res.data[0].mobile_img_path;
    });
  },
  methods: {
    getProduct(id, type = false) {
      if (type) {
        this.currentPage = 1;
      }
      let data = {
        type_id: id,
        page_index: this.currentPage,
        page_size: 20,
      };
      getWorksContent(data).then((res) => {
        if (res.status == 200) {
          this.navIndex = id;
          this.content = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    toDetail(item) {
      let obj = this.nav.find((e) => {
        return e.id == this.navIndex;
      });
      this.$router.push({
        path: `/CaseDet/${item.id}`,
        // query: { id: item.id, title: obj.title, typeId: obj.id },
      });
    },
    getWorks() {
      let params = {
        // cates: 25
      };
      getWorksListApi(params).then((res) => {
        if (res.status == 200) {
          this.nav = res.data;
          this.getProduct(this.nav[this.navIndex].id);
        }
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.product-nav {
  display: flex;
  width: 499px;
  border: 1px solid #becced;
  margin: 60px auto 0;
  &-div {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #000000;
    cursor: pointer;
  }
  .bavStyle {
    color: #ffffff;
    background-image: linear-gradient(180deg, #4e85ff 0%, #1d5eed 100%);
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  //background-color: #d3dce6;
}
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .product-nav {
    width: 300px;
  }
  .product-nav-div {
    width: 150px;
    font-size: 16px;
  }
  #fh5co-course {
    padding: 0;
  }
}

.img_Soft {
  width: 40%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  .container-item {
    width: 30%;
    // margin-bottom: 20px;
    cursor: pointer;
    margin: 0 1.666% 30px;
    // box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1);
    img {
      width: 100%;
    }
    div {
      text-align: center;
      padding: 10px 0;
      color: #333333;
      font-size: 12px;
      font-size: 100%;
    }
  }
}

.row {
  display: flex;
}

::v-deep .my-gallery {
  //deep深层作用选择器
  display: flex;
  height: 390px;
  figure {
    width: 30%;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 969px) {
  ::v-deep .my-gallery {
    //deep深层作用选择器
    display: flex;
    flex-direction: column;
    height: 100%;
    figure {
      width: 100%;
      margin: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.pagination {
  display: table;
  //   justify-content: center;
  margin: 40px auto;
}
::v-deep .pswp__button--share {
  display: none !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1d5eed !important;
}
</style>
