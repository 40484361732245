<template>
  <div>

    <Head :navId="5"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li :style="'background-image: url('+(bannerSwitch?banner:banner1)+');'">
          </li>
        </ul>
      </div>
    </aside>
    <div class="row text-center animate-box" style="margin-top:50px">
      <!-- <div class="col-md-6 col-md-offset-3 text-center fh5co-heading"> -->
      <h2>联系我们</h2>
      <p style="font-size: 14px;
    color: #c7c7c7">contact us</p>
      <!-- </div> -->
    </div>
    <img class="nav-img" :src="navImg" alt />
    <div id="fh5co-contact" style="padding-top:40px;">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-md-push-1 animate-box">
            <div class="fh5co-contact-info">
              <!-- <h3>联系地址</h3> -->
              <ul class="ul-class">
                <li class="icon-address">
                  <!-- <span class="iconfont">&#xe652;</span>&nbsp; -->
                  联系电话：{{selectData("site_telphone") }}
                </li>
                <li class="icon-phone">华东区： {{ selectData("east_china") }}</li>
                <li class="icon-phone">华西区： {{selectData("west_china") }}</li>
                <li class="icon-phone">华南区： {{selectData("south_china") }}</li>
                <li class="icon-mail">公司地址：{{selectData("company_address") }}</li>
                <li class="icon-mail">网址：{{selectData("company_url") }}</li>
                <li class="icon-ie">QQ：{{selectData("company_qq") }}</li>
                <li class="icon-ie"></li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-md-6 animate-box map">
            <div id="mapContainer"></div>
          </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import AMapLoader from "@amap/amap-jsapi-loader";
import { getBanner } from "@/api/home";

export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      map: null,
      infoWindow: null, //信息窗体
      markers: [],
      navImg: require("../static/index/nav-i.png"),
      mapList: [
        {
          name: "总部",
          address: "南京市栖霞区恒园路1号龙港科技园B1栋",
          lnglats: [118.866451, 32.155153],
        },
      ],
      setting: [],
      banner: "",
      banner1: ""
    };
  },
  computed: {
    getSetting() {
      return this.$store.state.setting;
    },
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  mounted() {
    this.$store.commit('setSeo')
    getBanner(5).then((res) => {
      this.banner = res.data[0].img_path;
      this.banner1 = res.data[0].mobile_img_path;
    });
    console.log(this.getSetting, "his.$store.state.setting");
  },

  methods: {
    selectData(i) {
      console.log(this.getSetting, "123456");
      let obj = this.getSetting.find((item) => {
        return item.menu_name === i;
      });
      return obj ? obj.value : "";
    },
    tohanzi(out_val) {
      console.log(out_val);
      if (out_val && out_val.indexOf("\\u") !== -1) {
        let valArr = out_val.split("\\u"),
          result = "";
        for (var j = 0, length = valArr.length; j < length; j++) {
          result += String.fromCharCode(parseInt(valArr[j], 16));
        }
        //如果不截取，则会出现空白字符，如何也消除不了
        return result.slice(1);
        // console.log(result.slice(1)); //测试内容
      } else {
        alert("不是unicode字符，无需解码!");
      }
    },
    initAMap() {
      AMapLoader.load({
        key: "916919393f49e9132baa000648891c5f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("mapContainer", {
            zoom: 14, //级别
            center: [118.86728, 32.15543], //中心点坐标
            viewMode: "3D", //使用3D视图
          });
          // this.initInfoWindow();
          this.setMapMarker();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 增加点标记
    setMapMarker() {
      // 创建 AMap.Icon 实例
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png", // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = [];
      this.mapList.forEach((item) => {
        // 遍历生成多个标记点
        let marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
          position: item.lnglats, // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        marker.content = `<div>${item.name}</div><div>${item.address}</div>`;
        marker.on("click", (e) => {
          this.openInfoWindow(e.target.content, e.lnglat);
        });
        makerList.push(marker);
      });
      this.map.add(makerList);
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    },
    // 打开信息窗口
    openInfoWindow(content, lnglat) {
      const infoWindow = new AMap.InfoWindow({
        content: content,
        position: lnglat,
      });
      infoWindow.open(this.map, lnglat);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .card-div {
    flex-direction: column;
    margin: 10px 10px 0 !important;
  }
  .product-nav {
    width: 300px !important;
  }
  .product-nav-div {
    width: 150px !important;
    font-size: 18px !important;
  }
  .card-div .img-div {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
  .text-div {
    // font-size: 20px !important;
    padding-bottom: 40px !important;
  }
  .radius-div {
    font-size: 16px !important;
    right: 20px;
  }
  .is-background {
    margin-top: 20px;
  }

  .product-top-img {
    width: 350px !important;
  }
  .ul-class {
    margin: 0 !important;
    width: 100% !important;
  }
  .col-md-5 {
    // width: 100%;
    margin-left: 0px !important;
  }
  .fh5co-contact-info ul li {
    padding: 0 !important;
    font-size: 14px;
  }
}

.col-md-6 {
  width: 100%;
  height: 500px;
  #mapContainer {
    width: 100%;
    height: 100%;
  }
}
.col-md-5 {
  width: 100%;
  margin-left: -100px;
}
.fh5co-contact-info {
  width: 100%;
}
.ul-class {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px auto 30px;
  display: flex;
  li {
    width: 50%;
    font-size: 18px;
  }
}
.nav-img {
  width: 30px;
  margin: 10px auto;
  display: block;
}
</style>
