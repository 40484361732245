<template>
  <div>

    <Head></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li>
            <div class="overlay-gradient"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center slider-text">
                  <div class="slider-text-inner">
                    <h1 class="heading-section">解决方案</h1>
                    <p>
                      针对各行业业务特性，打造个性化行业解决方案，为用户提供一站式产品与服务
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>

    <div id="fh5co-course">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>解决方案</h2>
            <!--<p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>-->
          </div>
        </div>
        <div class="row">
          <div
            v-for="(item,index) in schemeList"
            :key="index"
          >
            <div class="col-md-6 animate-box">
              <div class="course">
                <a
                  href="javascript:void(0);"
                  class="course-img"
                  :style="{'background-image':'url('+item.image+')'}"
                >
                </a>
                <div class="desc">
                  <h3>
                    <a href="javascript:void(0);">{{item.cate_name}}</a>
                  </h3>
                  <p class="word-p">
                    {{item.summary}}
                  </p>
                  <span
                    @click="checkDetails(item)"
                    style="cursor:pointer"
                    class="check-more"
                  >查看更多</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import { getSolutionApi } from "@/api/index";
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      schemeList: [],
      newId: "",
    };
  },
  mounted() {
    this.getSolution();
  },
  methods: {
    /**获取解决方案 */
    getSolution() {
      getSolutionApi().then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.schemeList = res.data;
          this.schemeList.forEach((item) => {
            let img = item.image;
            if(img.includes('https://')){
              item.image = img;
            } else {
              item.image = this.$BaseUrl + item.image;
            }
          });
        }
      });
     
    },
    checkDetails(row) {
      //查看更多点击事件
      const id = row.id;
      this.$router.push({ name: "SolutionDetails", query: { id: id } });
    },
  },
};
</script>
<style lang="less" scoped>
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
