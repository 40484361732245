import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Skill from '../views/News.vue'
import Scheme from '../views/Scheme.vue'
import Concept from '../views/Concept.vue'
import SolutionDetails from "../views/SolutionDetails.vue"
import Newsdetails from "../views/Newsdetails.vue"
import Software from "../views/Software.vue"
import Product from "../views/Product.vue"
import Convention from "@/views/Convention";
import Case from "@/views/Case";
import productDet from "@/views/productDet";
import Index from "@/views/index";
import CaseDet from "@/views/CaseDet";
import search from "@/views/search";


const originalPush=VueRouter.prototype.push
VueRouter.prototype.push=function push(location){
  return originalPush.call(this,location).catch(err=>err)
}
Vue.use(VueRouter)

const routes = [
  // { path: '/', redirect: '/index' },
  {
    path: '/', name: 'index', component: Index
  },
  {
    path: '/HomePage', name: 'HomePage', component: HomePage
  },
  {
    path: '/Search', name: 'search', component: search
  },
  {
    path: '/CaseDet/:id', name: 'CaseDet', component: CaseDet
  },
  {
    path: '/ProductDet/:id', name: 'productDet', component: productDet
  },
  {
    path: '/About', name: 'About', component: About
  },
  {
    path: '/Product', name: 'Product', component: Product
  },
  {
    path: '/Case', name: 'Case', component: Case
  },
  {
    path: '/Contact', name: 'Contact', component: Contact
  },
  {
    path: '/Skill', name: 'Skill', component: Skill
  },
  {
    path: '/Scheme', name: 'Scheme', component: Scheme
  },
  {
    path: '/Concept', name: 'Concept', component: Concept
  },
  {
    path: '/SolutionDetails', name: 'SolutionDetails', component: SolutionDetails
  },
  {
    path: '/Newsdetails/:id', name: 'Newsdetails', component: Newsdetails
  },
  {
    path: '/Software', name: 'Software', component: Software
  },
  {
    path: '/Convention', name: 'Convention', component: Convention
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router
