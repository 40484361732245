<template>
  <div>

    <Head :showSearch="true" :navId="0" @inputChange="search"></Head>

    <div id="fh5co-course">
      <div class="container" v-if="content&&content.length">
        <div class="container-item" @click="detail(item)" v-for="(item,i) in content" :key="i">
          <div style="overflow: hidden;">
            <img :src="item.image_input" />
          </div>
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div v-else class="search-none">
        <img src="../static/images/search1.png" class="search-img" alt="">
        <div>暂无更多产品</div>
      </div>
    </div>
    <!-- @size-change="changeSizeClick" -->
    <!-- :current-page="currentPage" -->
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="9" @current-change="currentPageClick" style="width: 100%; text-align: center;margin-bottom: 50px;"></el-pagination>

    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import { getproductList, productList, searchIndex } from "../api/index";
import { getBanner } from "@/api/home";

export default {
  components: {
    Head,
    Footer,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data() {
    return {
      schemeList: [],
      navIndex: 0,
      content: [
        {
          img: require("../static/product/product1.png"),
          title: "全自动韦氏智力测评系统（成人版）",
        },
        {
          img: require("../static/product/product2.png"),
          title: "全自动韦氏智力测评系统（儿童版）",
        },
        {
          img: require("../static/product/product3.png"),
          title: "全自动韦氏智力测评系统（幼儿版）",
        },
        {
          img: require("../static/product/product4.png"),
          title: "心理CT测评分析系统",
        },
        {
          img: require("../static/product/product5.png"),
          title: "计算机行为矫正治疗系统",
        },
        {
          img: require("../static/product/product6.png"),
          title: "计算机化的认知行为矫正治疗系统",
        },
        {
          img: require("../static/product/product7.png"),
          title: "计算机认知评估与康复训练系统",
        },
        {
          img: require("../static/product/product8.png"),
          title: "语言评估与训练系统",
        },
      ],
      content1: [
        {
          img: require("../static/product/therapy1.png"),
          title: "心身一体诊疗系统",
        },
        {
          img: require("../static/product/therapy2.png"),
          title: "儿童智力评估与训练系统",
        },
        {
          img: require("../static/product/therapy3.png"),
          title: "孕产妇身心健康管理系统",
        },
        {
          img: require("../static/product/therapy4.png"),
          title: "计算机神经功能康复评定系统",
        },
        {
          img: require("../static/product/therapy5.png"),
          title: "团体型音乐心理治疗系统",
        },
      ],
      nav: [],
      total: 1,
      currentPage: 1,
      banner: "",
      banner1: "",
      content: [],
      title: "",
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch;
    },
  },
  created() {
    // this.getWorks();
    getBanner(2).then((res) => {
      this.banner = res.data[0].img_path;
      this.banner1 = res.data[0].mobile_image;
    });
  },
  mounted() {
    this.$store.commit("setSeo");
    this.navIndex = this.$route.query.id || 0;
  },
  methods: {
    search(e) {
      this.title = e;
      let data = {
        page_size: 9,
        page_index: this.currentPage,
        title: e,
      };
      searchIndex(data).then((res) => {
        if (res.status == 200) {
          this.content = res.data.data;
          this.total = res.data.total;
        }
      });
      // this.currentPage=1
      // let data = {
      //   // type_id: id,
      //   // page_index: this.currentPage,
      //   // page_size: 20,
      //   title:e
      // };
      // searchIndex(data).then((res) => {
      //   if (res.status == 200) {
      //     this.content = res.data.data;
      //     this.total = res.data.total;
      //   }
      // });
    },
    currentPageClick(val) {
      this.currentPage = val;
      this.search(this.title);
    },
    getProduct(id, type = false) {
      if (type) {
        this.currentPage = 1;
      }
      let data = {
        type_id: id,
        page_index: this.currentPage,
        page_size: 9,
      };
      productList(data).then((res) => {
        if (res.status == 200) {
          this.navIndex = id;
          this.content = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    detail(item) {
      let path = "";
      if (item.mode == 2) {
        path = `/productDet/${item.id}`;
      } else if (item.mode == 1) {
        path = `/Newsdetails/${item.id}`;
      } else if (item.mode == 3) {
        path = `/CaseDet/${item.id}`;
      }
      this.drawer1 = false;
      return this.$router.push({
        path: path,
        // query: { id: item.id, title: item.title, typeId: item.cid },
      });
      // let obj = this.nav.find((e) => {
      //   return e.id == this.navIndex;
      // });
      // const id = item.id;
      // // const activeName = this.activeName;
      // this.$router.push({
      //   path: "/productDet",
      //   query: { id: id, title: obj.title, typeId: obj.id },
      // });
    },
    getWorks() {
      let params = {
        // cates: 25
      };
      getproductList(params).then((res) => {
        if (res.status == 200) {
          let data = res.data;
          this.nav = data;
          this.getProduct(this.nav[this.navIndex].id);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.search-none {
  margin-bottom: 60px;
  & > div {
    font-size: 18px;
    margin-top: -10px;
    color: #999999;
    text-align: center;
  }
}
.search-img {
  width: 500px;
  display: block;
  margin: 100px auto 0px;
}
.product-nav {
  display: flex;
  width: 499px;
  border: 1px solid #becced;
  margin: 60px auto 0;
  &-div {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
  }
  .bavStyle {
    color: #ffffff;
    background-image: linear-gradient(180deg, #4e85ff 0%, #1d5eed 100%);
  }
}

@media screen and (max-width: 768px) {
  .product-nav {
    width: 300px;
  }
  .product-nav-div {
    width: 150px;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  //background-color: #d3dce6;
}
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}

.img_Soft {
  width: 40%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;

  .container-item {
    width: 30%;
    // margin-bottom: 20px;
    margin: 0 1.666% 30px;
    cursor: pointer;

    img {
      width: 100%;
    }
    div {
      text-align: center;
      padding: 10px 0;
      color: #333333;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.row {
  display: flex;
}

::v-deep .my-gallery {
  //deep深层作用选择器
  display: flex;
  height: 390px;
  figure {
    width: 30%;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 969px) {
  ::v-deep .my-gallery {
    //deep深层作用选择器
    display: flex;
    flex-direction: column;
    height: 100%;
    figure {
      width: 100%;
      margin: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

::v-deep .pswp__button--share {
  display: none !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1d5eed !important;
}
</style>
