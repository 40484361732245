import request from '@/utils/request'


//获取解决方案
export function getSolutionApi(data) {
  return request({
    url: '/api/index/getSolution',
    method: 'get',
    params: data
  })
}

//友情链接
export function linkApi() {
  return request({
    url: '/api/index/link',
    method: 'get',
    params: ''
  })
}

//获取图片列表
export function getImageListApi() {
  return request({
    url: '/api/web/indexImages/index',
    method: 'get',
    params: ''
  })
}

//首页关于我们
export function AboutApi() {
  return request({
    url: '/api/web/about/getTitleList',
    method: 'get',
    data: ''
  })
}
//首页关于我们
export function AboutContent(data) {
  return request({
    url: '/api/web/about/getAboutInfo',
    method: 'post',
    data: data
  })
}
//新闻列表
export function getListApi(data) {
  return request({
    url: '/api/news/getList',
    method: 'post',
    data: data
  })
}

//新闻分类
export function trpeListApi() {
  return request({
    url: '/api/news/trpeList',
    method: 'get',
    params: ''
  })
}

//动态新闻详情
export function getNewsDetailsApi(id) {
  return request({
    url: `/api/news/detail/${id}`,
    method: 'get',
  })
}

//动态新闻列表
export function getNewListApi() {
  return request({
    url: "/api/news/getList",
    method: 'post',
  })
}

//解决方案详情
export function getsolutionDetailApi(id) {
  return request({
    url: `/api/index/solutionDetail/${id}`,
    method: 'get',
  })
}

//导航栏菜单
export function menuApi(id) {
  return request({
    url: "/api/index/menu",
    method: 'get',
  })
}

//热门新闻
export function getHotListApi(pageSize) {
  return request({
    url: "/api/news/getHotList",
    method: 'post',
    data: pageSize
  })
}

//获取软著列表
export function getproductList(data) {
  return request({
    url: "/api/web/product/type/list",
    method: 'get',
    params: data
  })
}
export function getNewsList(data) {
  return request({
    url: "/api/web/news/list",
    method: 'get',
    params: data
  })
}
export function newsDetail(data) {
  return request({
    url: "/api/web/news/detail",
    method: 'post',
    data: data
  })
}
export function getNewsCate(data) {
  return request({
    url: "/api/web/news/type/list",
    method: 'get',
    params: data
  })
}
//获取软著列表
export function getWorksListApi(data) {
  return request({
    url: "/api/web/cases/type/list",
    method: 'get',
    params: data
  })
}

export function getWorksContent(data) {
  return request({
    url: "/api/web/cases/list",
    method: 'get',
    params: data
  })
}
//获取软著列表
export function productList(param) {
  return request({
    url: "/api/web/product/list",
    method: 'get',
    params: param
  })
}
export function productDetail(param) {
  return request({
    url: "/api/web/product/detail",
    method: 'post',
    data: param
  })
}
//获取平台自律公约
export function getConventionApi(param) {
  return request({
    url: "/api/convention/detail",
    method: 'post',
    data: param
  })
}


//新闻分类
export function settings() {
  return request({
    url: '/api/web/settings/index',
    method: 'get',
    params: ''
  })
}

export function linksList() {
  return request({
    url: '/api/web/links/index',
    method: 'get',
    params: ''
  })
}

export function productHot(param) {
  return request({
    url: '/api/web/product/getHot',
    method: 'get',
    params: param
  })
}

export function casesHot(param) {
  return request({
    url: '/api/web/case/getHot',
    method: 'get',
    params: param
  })
}
export function getNewsHot(param) {
  return request({
    url: '/api/web/news/getHot',
    method: 'get',
    params: param
  })
}

export function searchIndex(param) {
  return request({
    url: '/api/web/search/index',
    method: 'get',
    params: param
  })
}