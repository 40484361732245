<template>
  <div>
    <footer id="fh5co-footer" role="contentinfo">
      <!-- :style="{ backgroundImage: 'url(' + imgSrc + ')' }" -->
      <div class="foot-contact">
        <div class="foot-fixed">

          <div class="foot-fixed-item">
            <div class="foot-left">
              <div class="foot-fixed1"></div>
              <div>{{selectData("site_telphone") }}</div>
            </div>
            <div class="foot-fixed-1"></div>
          </div>

          <div class="foot-line"></div>
          <!-- <div class="foot-fixed-2"></div> -->
          <div class="foot-fixed-item1">
            <div class="foot-left">
              <!-- <div class="foot-fixed2"></div>
              <div>在线客服</div>-->
            </div>
            <div @click="showDetail" class="foot-fixed-2"></div>
          </div>
          <div class="foot-line"></div>
          <a :href="'http://wpa.qq.com/msgrd?v=3&uin='+selectData('company_qq')+'&site=qq&menu=yes'" class="foot-fixed-3"></a>
          <div class="foot-line"></div>
          <el-backtop>
            <div class="foot-fixed-4"></div>
          </el-backtop>
        </div>
      </div>
      <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop> -->
      <div class="overlay"></div>
      <div class>
        <div class="row foot-div">
          <!-- <div class="col-md-3 fh5co-widget">
            <img :src="logoSrc" />
            <p>
              公司总部设在南京，公司集聚了资深的行业技术与业务专家技术人员占比超过80%，且公司拥有多项软著和专利。
            </p>
          </div>-->
          <div class="col-md-2 col-sm-4 my-xs-4 col-md-push-1 fh5co-widget about-width">
            <h3>关于我们</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <!-- <li @click="aboutClick()" class="solution-class">公司简介</li>
              <li @click="aboutClick()" class="solution-class">企业文化</li>
              <li @click="aboutClick()" class="solution-class">发展历程</li> -->
              <li v-for="(item,index) in nav.about_us" :key="index" @click="aboutClick(index)" class="solution-class">
                {{item.title}}
              </li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 my-xs-4 col-md-push-1 fh5co-widget">
            <h3>产品展示</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li v-for="(item,index) in nav.project" :key="index" class="solution-class" @click="projectClick(index)">
                {{item.title}}
              </li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 my-xs-4 col-md-push-1 fh5co-widget">
            <h3>案例展示</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <!-- <li @click="aboutClick()" class="solution-class">检测评估类案例</li>
              <li @click="aboutClick()" class="solution-class">训练治疗类案例</li> -->
              <li v-for="(item,index) in nav.cases" :key="index" class="solution-class" @click="casesClick(index)">
                {{item.title}}
              </li>
            </ul>
          </div>

          <div class="col-md-2 col-sm-4 my-xs-4 col-md-push-1 fh5co-widget about-width">
            <h3>新闻资讯</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <!-- <li @click="aboutClick()" class="solution-class">公司新闻</li>
              <li @click="aboutClick()" class="solution-class">行业新闻</li> -->
              <li v-for="(item,index) in nav.new" :key="index" class="solution-class" @click="newsClick(index)">
                {{item.title}}
              </li>
            </ul>
          </div>

          <div style="text-align:left;width: auto;padding-right:40px" class="col-md-2 col-sm-4 col-md-push-1 fh5co-widget">
            <h3>联系我们</h3>
            <ul class="fh5co-footer-links fh5co-footer-ul">
              <li @click="contactClick()" class="solution-class1">
                <span style="font-size:20px" class="iconfont">&#xe652;</span>
                {{selectData("company_address") }}
              </li>
              <li @click="contactClick()" class="solution-class1">
                <span class="iconfont">&#xe66e;</span>
                23994441@qq.com
              </li>
              <li @click="contactClick()" class="solution-class1">
                <span class="iconfont">&#xe62a;</span>
                {{selectData("site_telphone")}}
              </li>
            </ul>
          </div>

          <!-- <div class="col-md-2 col-sm-4 col-xs-5 col-md-push-1 fh5co-widget">
            <h3>淘宝旗舰店</h3>
          </div> -->

          <div class="col-md-2 col-sm-4 col-xs-5 col-md-push-1 fh5co-widget account_qr">
            <img :src="selectData('official_account_qr')">
            <h4>扫一扫，关注我们微信</h4>
          </div>
        </div>

        <div class="row copyright">
          <div class="col-md-12 text-center">
            <p>
              <small class="block">
                Copyright © 2024-{{ year }}
                <!-- NjZhidian.Co.,Ltd.All Rights Reserved&nbsp;&nbsp;-&nbsp;&nbsp;北京东晓通康科技发展有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;-&nbsp;&nbsp; -->
                {{selectData('copy_right_lang')}}&nbsp;&nbsp;-&nbsp;&nbsp;{{selectData('copy_right')}}&nbsp;&nbsp;版权所有&nbsp;&nbsp;-&nbsp;&nbsp;
                <a target="_blank" href="https://beian.mps.gov.cn" style="height: 20px">
                  <img src="../../assets/beian_icon.png" alt style="display: inline-block;height: 14px;margin-bottom: 3px" /> {{selectData('record_number')}}
                  <!-- <span style="color:#ffffff">技术支持：木子天禾科技网站地图</span> -->
                </a>
              </small>
            </p>
          </div>

          <div class="col-md-12 text-center">友情链接：
            <a style="color:#ffffff;margin-right:5px;" v-for="(item,i) in getLink" :key="i" :href="item.url" target="_blank">{{item.title}}</a>
          </div>
        </div>
      </div>
    </footer>
    <div class="gototop js-top">
      <a href="#" class="js-gotop">
        <i class="icon-up"></i>
      </a>
    </div>

    <el-dialog :show-close="false" :visible.sync="kfShow" width="60%">
      <div class="dialog-kf">
        <div class="dialog-kf-title">扫描以下二维码 联系在线客服</div>
        <div class="dialog-qr">
          <div class="dialog-qr-item" v-if="getQr[0]">
            <img :src="getQr[0].image" />
            <span>客服1</span>
          </div>
          <div class="dialog-qr-item" v-if="getQr[1]">
            <img :src="getQr[1].image" />
            <span>客服2</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { settings, linksList } from "@/api/index";
import { kefuImage, footNav } from "@/api/home";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {
      logoSrc: require("../../static/images/logo.png"),
      f: require("../../static/images/img_bg_4.jpg"),
      qrCode1: require("../../static/images/qr-code1.png"),
      qrCode2: require("../../static/images/qr-code2.png"),
      schemeList: [],
      classifyList: [],
      cate_name: "",
      year: "",
      kfShow: false,
      setting: {},
      phone: "",
      link: [],
      nav: {},
    };
  },
  computed: {
    getLink() {
      return this.$store.state.link;
    },
    getQr() {
      return this.$store.state.qrCode;
    },
    getSetting() {
      return this.$store.state.setting;
    },
  },
  created() {
    // this.getSettings();
    // this.qrImg();
    footNav().then((res) => {
      this.nav = res.data;
      console.log(res.data, "dataa");
    });
  },
  methods: {
    qrImg() {
      kefuImage().then((res) => {
        this.qrCode1 = res.data[0].image;
        this.qrCode2 = res.data[1].image;
      });
    },
    selectData(i) {
      let obj = this.getSetting.find((item) => {
        return item.menu_name === i;
      });
      if (obj) {
        return obj.value;
      } else {
        return "";
      }
    },
    // getSettings() {
    //   settings().then((res) => {
    //     this.$emit("getSettings", res.data);
    //     let obj = res.data.find((item) => {
    //       return item.info == "QQ";
    //     });
    //     // obj.value=obj.value.split('"')[1]

    //     this.setting = obj;
    //     let setString = JSON.stringify(res.data);
    //     let ph = res.data.find((item) => {
    //       return item.info == "联系电话";
    //     });
    //     this.phone = ph.value;
    //     // console.log(setString,'setString')
    //     window.localStorage.setItem("settings", setString);
    //   });
    // },
    showDetail() {
      this.kfShow = true;
    },
    //置顶、至底部
    getSolution() {
      getSolutionApi().then((res) => {
        if (res.code == 200) {
          this.schemeList = res.data;
        }
      });
    },
    solutionClick(val) {
      const id = val.id;
      this.$router.push({ path: "/SolutionDetails", query: { id: id } });
    },
    trpeListMethod() {
      trpeListApi().then((res) => {
        if (res.code == 200) {
          this.classifyList = res.data;
        }
      });
    },
    newClick(val) {
      this.cate_name = val.cate_name;
      this.$router.push({
        path: "/Skill",
        query: { cate_name: this.cate_name },
      });
    },
    contactClick() {
      this.$router.push({ path: "/Contact" });
    },
    aboutClick(i) {
      this.$router.push({ path: "/About", query: { id: i } });
    },
    newsClick(i) {
      this.$router.push({ path: "/Skill", query: { id: i } });
    },
    projectClick(i) {
      this.$router.push({ path: "/Product", query: { id: i } });
    },
    casesClick(i) {
      this.$router.push({ path: "/Case", query: { id: i } });
    },
    // Software() {
    //   this.$router.push({ path: "/Software", query: {} });
    // },
    dateFormat() {
      let date = new Date();
      let year = date.getFullYear();
      this.year = year;
    },
  },
};
</script>
<style lang="less" scoped>
.solution-class {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.solution-class1 {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .fh5co-widget {
    width: 10%;
  }
  .about-width {
    width: 8% !important;
  }
}
@media screen and (max-width: 768px) {
  .about-width{
    width: 27% !important;
  }
  .my-xs-4 {
    width: 32%;
  }
}
@media screen and (max-width: 768px) {
  .dialog-qr {
    flex-direction: column;
  }
}
.fh5co-footer-ul {
  // width: 60px;
}
.foot-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.foot-contact {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 999;
  display: flex;
  .foot-fixed {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;

    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    .foot-line {
      width: 50px;
      border-bottom: 1px solid #e6e6e6;
    }
    &-1 {
      background-image: url(../../static/foot/foot1_a.png);
    }
    &-2 {
      background-image: url(../../static/foot/foot2_a.png);
    }
    &-3 {
      background-image: url(../../static/foot/foot3_a.png);
    }
    &-4 {
      background-image: url(../../static/foot/foot4_a.png);
    }
    .foot-fixed-item:hover {
      .foot-fixed-1 {
        background-image: url(../../static/foot/foot1.png);
      }

      .foot-left {
        opacity: 1;
        left: -200px !important;
      }
    }
    .foot-fixed-item1:hover {
      .foot-fixed-2 {
        display: block;
        background-image: url(../../static/foot/foot2.png);
      }

      // .foot-left {
      //   opacity: 1;
      //   left: -200px !important;
      // }
    }
    &-2:hover {
      background-image: url(../../static/foot/foot2.png);
    }
    &-3:hover {
      background-image: url(../../static/foot/foot3.png);
    }
    &-4:hover {
      background-image: url(../../static/foot/foot4.png);
    }

    .foot-left {
      display: flex;
      opacity: 0;
      min-width: 180px;
      left: 0;
      position: absolute;
      align-items: center;
      background-color: #ffffff;
      color: #1d5eed;
      padding: 10px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in;
      .foot-fixed1,
      .foot-fixed2 {
        width: 30px;
        height: 30px;
        background-size: 100% 100%;
        margin-right: 20px;
      }
      .foot-fixed1 {
        background-image: url(../../static/foot/foot1.png);
      }
      .foot-fixed2 {
        background-image: url(../../static/foot/foot2.png);
      }
    }

    &-1,
    &-2,
    &-4,
    &-3 {
      margin: 10px;
      background-size: 100% 100%;
      // padding: 10px;
      width: 30px;
      height: 30px;
    }
  }
}
.el-backtop {
  position: relative;
  top: 0 !important;
  right: 0 !important;
  width: auto;
  height: auto;
  border-radius: 0;
  box-shadow: 0 0;
}
.dialog-kf {
  padding: 30px;
  border-radius: 20px;
  background-size: 100% 100%;
  background-image: url(../../static/images/kf-back.png);
}
.el-dialog__header {
  padding: 0;
}

.dialog-kf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 26px;
    margin-top: 20px;
  }
  .dialog-qr {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000000;
      margin-bottom: 20px;
      img {
        width: 160px;
        height: 160px;
        margin-bottom: 10px;
      }
    }
  }
}
::v-deep .el-dialog {
  background-color: #ffffff00;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}

.account_qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  img {
    margin-bottom: 15px;
    width: 120px !important;
    height: 120px;
  }
  h4 {
    color: #ffffff;
    font-size: 14px;
  }
}
</style>
